@import "variables";
@import "mixins";

div#root > div {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fdfdfd;
  color: $black-text-color;
  font-family: "Roboto", sans-serif;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

p.lead {
  @include h4;
}

.logo-text {
  font-size: 21px;
  margin-right: 24px;
  margin-top: 4px;
  display: inline-block;
}

.standard-content {
  margin-top: 40px;
  margin-bottom: 60px;
}

.footer {
  margin-top: 40px;
  margin-bottom: 60px;
}

.footer-bg {
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
  height: 452px;
  background: url("images/bottom.svg");
  background-size: cover;
}

.copyright-note {
  text-align: center;
  margin: 0;
  @include h6;
  &.light {
    color: #fff;
  }
}

.form-control {
  border-color: $light-gray;
  &:focus {
    border-color: $purple-light;
    box-shadow: 0px 0px 2px $purple-light;
  }
}

.form-group {
  .invalid {
    @include h6;
    margin-top: 4px;
    color: $error-color;
  }
}

.alert-w3 {
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}

.navbar-w3 {
  //background: linear-gradient(4.01deg, #3f39a1 10.6%, #5a5be9 97.22%);
  .nav-link {
    color: #fff;
    font-weight: bold;
    @include h5;
  }
  .navbar-brand {
    @include h5;
    font-weight: normal;
    color: #fff;
  }
  a.nav-link {
    border-bottom: 4px solid transparent;
    &.active,
    &:hover {
      color: #fff;
      border-color: $orange;
    }
  }

  span.badge {
    @include h6;
    font-weight: normal;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

.navbar-w3-light {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
  .nav-link {
    color: $dark-gray;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 4px solid transparent;
  }
  .navbar-brand {
    @include h5;
    font-weight: normal;
    color: $black-text-color;
  }
  a.nav-link.active,
  a.nav-link:hover {
    border-color: $orange;
  }
  span.badge {
    @include h6;
    font-weight: normal;
    background: $purple-light-20;
    border-radius: 4px;
    color: $purple-light;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}

.progress-bar.dynamic {
  -webkit-transition: width 1500ms;
  transition: width 1500ms;
}

.sticky-left-menu {
  position: sticky;
  top: 80px;
}

nav {
  color: #fff;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $orange !important;
  border-color: $orange !important;
  border-radius: 4px;
  &:hover {
    background-color: $dark-orange !important;
    border-color: $dark-orange !important;
  }
}

.btn-outline-primary {
  color: $orange;
  border-color: $orange;
  &:hover {
    color: $dark-orange;
    background-color: #fff;
    border-color: $dark-orange;
  }
}

.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: $black-text-color;
  }
}

.google-visualization-tooltip {
  width: 413px !important;
}
.product-page-top-bg {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  background: linear-gradient(5.59deg, #3f39a1 4.13%, #5a5be9 95.24%);
  .what {
    width: 100%;
    height: 292px;
    background: url("images/top.svg");
    opacity: 0.4;
    background-size: cover;
    position: absolute;
    bottom: 0;
  }
}

.page-top-bg {
  width: 100%;
  height: 120px;
  position: absolute;
  z-index: -1;
  top: 0;
  height: 60px;
  background: linear-gradient(4.01deg, #3f39a1 10.6%, #5a5be9 97.22%);
}

.modal-70w {
  max-width: 100%;
}

@media (min-width: 576px) {
  .modal-70w {
    max-width: 70%;
  }
}
