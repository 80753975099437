@import "../variables.scss";
@import "../mixins.scss";

.contact-page {
  .card {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
    border-radius: 4px;
    border: 0;
    .header {
      margin-top: 24px;
      font-weight: bold;
      @include h4;
    }
    .description {
      @include h5;
    }
  }
}

@media (min-width: 576px) {
  .contact-form {
    padding: 40px;
  }
}

.contact-form {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  h2 {
    margin-bottom: 32px;
  }
}
