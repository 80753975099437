@mixin h1 {
  font-size: 38px;
  line-height: 42px;
}

@mixin h2 {
  font-size: 32px;
  line-height: 42px;
}

@mixin h3 {
  font-size: 24px;
  line-height: 36px;
}

@mixin h4 {
  font-size: 18px;
  line-height: 28px;
}

@mixin h5 {
  font-size: 14px;
  line-height: 22px;
}

@mixin h6 {
  font-size: 12px;
  line-height: 18px;
}

@mixin h7 {
  font-size: 10px;
  line-height: 15px;
}
