@import '../variables.scss';
@import '../mixins.scss';

div.product-page {
    .intro-section {
        color: #fff;
        p {
            margin-bottom: 37px;
            text-align: justify;
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
    
    .general-stats-section {
        h2 {
            margin-top: 80px;
            margin-bottom: 32px;
            text-align: center;
            @include h2;
        }
    
        .card {
            width: 350px;
            height: 86px;
            margin-bottom: 12px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
            border-radius: 4px;
            border: none;
    
            span{
                display: block;
            }
    
            .card-body{
                padding: 16px;
                span.name {
                    @include h5;
                }
                span.value {
                    @include h4;
                    margin-top: 4px;
                    font-weight: bold;
                }
            }
            &:hover {
                background: #F6F7FE;
                color: $purple-light;
            }
        }
    }
    
    .detected-scores-section {
        h2 {
            margin-top: 80px;
            margin-bottom: 32px;
            text-align: center;
            @include h2;
        }
        .nav-left-tab {
            .nav-item {
                a {
                    padding: 10px;
                    background: $light-gray-15;
                    border-radius: 4px;
                    margin-bottom: 4px;
                    color: $black-text-color;
    
                    &.active {
                        background: $orange-8;
                        color: $orange;
                    }
                }
            }
        }
        
        .nav-top-tab {
            border-bottom: 1px solid $light-gray;
            padding-left: 11px;
            .nav-item {
                a {
                    margin-bottom: 0;
                    color: $black-text-color;
    
                    &.active {
                        color: $orange;
                        border-bottom: 2px solid $orange;
                    }
                }
            }
            .show-info{
                color: $purple-light;
                @include h7;
                svg {
                    margin-left: 4px;
                }
            }
        }
    
        .tab-content {
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
            border-radius: 4px;
            .tab-pane {
                .tab-content {
                    .tab-pane {
                        min-height: 530px;
                        padding: 24px;
                        text-align: justify;
                        overflow: auto;
                        &.chart-tab {
                            // set custom stroke width for google chart bar 
                            path {
                                stroke-width: 4;
                            }
                        }
                        &.readability-scores{
                            padding: 15px;
                            overflow: auto;
                            .usage-note{
                                overflow: auto;
                                height: 50px;
                                img {
                                    float: left;
                                    margin-right: 8px;
                                }
                                span {
                                    @include h7;
                                }
                            }
                            .card {
                                width: 280px;
                                height: 138px;
                                margin: 8px;
                                float: left;
                                box-sizing: border-box;
                                border-radius: 4px;
                                &:hover {
                                    background: $surface-blue;
                                    border: 1px solid $purple-light;
                                    color: $purple-light;
                                }
                                span {
                                    display: block;
                                    &.name {
                                        @include h5;
                                    }
                                    &.value {
                                        @include h4;
                                        font-weight: bold;
                                        margin-top: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .time-toggler {
            float: right;
            margin-top: -57px;
        }

        /* readability heatmap */
        .readability-heatmap {
            &.sent{
                &.level-0 {
                    background: $heatmap-green;
                }
                &.level-1 {
                    background: $heatmap-green-80
                }
                &.level-2 {
                    background: $heatmap-yellow;
                }
                &.level-3 {
                    background: $heatmap-red-30;
                }
                &.level-4 {
                    background: $heatmap-red-80;
                }
            }
        }

        /* sentiment heatmap */
        .sentiment-heatmap {
            &.sent{
                &.level-0 {
                    background: $heatmap-red;
                }
                &.level-1 {
                    background: $heatmap-yellow;
                }
                &.level-2 {
                    background: $heatmap-green;
                }
            }
        }

        /* aggression heatmap */
        .aggression-heatmap {
            &.sent{
                &.level-0 {
                    background: $heatmap-yellow;
                }
                &.level-1 {
                    background: $heatmap-red-30;
                }
                &.level-2 {
                    background: $heatmap-red-80;
                }
            }
        }

        /* discrimination heatmap */
        .discrimination-heatmap {
            &.sent {
                &.level-0 {
                    background: $heatmap-yellow;
                }
                &.level-1 {
                    background: $heatmap-red-30;
                }
                &.level-2 {
                    background: $heatmap-red-80;
                }
            }
        }

        /* toxic heatmap */
        .toxic-heatmap {
            &.sent {
                &.level-0 {
                    background: $heatmap-yellow;
                }
                &.level-1 {
                    background: $heatmap-red-30;
                }
                &.level-2 {
                    background: $heatmap-red-80;
                }
            }
        }

        .legends {
            overflow: auto;
            div{
                float: left;
                margin-right: 40px;
                .color {
                    display: block;
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                    float: left;
                    margin-top: 3px;
                    &.sentiment, &.readability {
                        &.level-0 {
                            background: $heatmap-red;
                        }
                        &.level-1 {
                            background: $heatmap-yellow;
                        }
                        &.level-2 {
                            background: $heatmap-green;
                        }
                    }
                    &.aggression, &.discrimination,&.insult {
                        &.level-0 {
                            background: $heatmap-yellow;
                        }
                        &.level-1 {
                            background: $heatmap-red;
                        }
                    }
                }
                .text{
                    @include h6;
                }
            }
        }

    }
    
    .advanced-data-section {
        h2 {
            margin-top: 80px;
            margin-bottom: 32px;
            text-align: center;
            @include h2;
        }
    
        .topic-card {
            margin-bottom: 12px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
            border-radius: 4px;
            border: 0;
            text-align: left;
            display: flex;
            flex-direction: inherit;
            width: 100%;
            height: 68px;
            overflow: hidden;
    
            &.open {
                height: auto;
            }
    
            &.extendable:hover {
                cursor: pointer;
            }
    
            svg {
                margin-top: 24px;
                margin-right: 12px;
            }
    
            .topic-name {
                width: 90px;
                margin: 20px 16px;
                span {
                    @include h4;
                    font-weight: bold;
                    margin-right: 5px;
                }
            }
    
            .topic-list {
                width:  calc(100% - 80px);
                margin-top: 4px;
                margin-bottom: 4px;
                span.topic-tag {
                    background: $surface-blue;
                    border-radius: 20px;
                    padding: 9px 12px;
                    display: inline-block;
                    margin: 8px;
                    @include h5;
                    &:hover {
                        cursor: auto;
                        color: $purple-light;
                    }
                }
            }
        }
    }
    
    .text-analysis-form {
        padding: 24px 24px 34px 24px;
        overflow: auto;
        background: #FFFFFF;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.075);
        border-radius: 4px;
        p.note {
            color: $light-gray;
            @include h5;
        }
    }

    .contact-form {
        margin-top: 80px;
    }
}