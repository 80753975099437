$purple-dark: #3F39A1;
$purple-light: #5A5BE9;
$orange: #F15E1F;
$dark-orange: #DB4F13;
$success-color: #28A745;
$error-color: #DC3545;

$black-text-color: #030303;
$dark-gray: #696969;
$light-gray: #CED4DA;

$surface-blue: #F6F7FE;
$light-gray-15: rgba(206, 212, 218, 0.15);
$orange-8: rgba(241, 94, 31, 0.08);
$purple-light-20: rgba(90, 91, 233, 0.20);

$heatmap-red: rgb(255, 214, 214);
$heatmap-red-80: rgba(255, 214, 214, 0.8);
$heatmap-red-30: rgba(255, 214, 214, 0.3);
$heatmap-yellow: rgb(255, 245, 193);
$heatmap-green: rgb(214, 240, 202);
$heatmap-green-80: rgba(214, 240, 202, 0.8);