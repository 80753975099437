@import '../variables.scss';
@import '../mixins.scss';

.features-page {
    .features-block {
        margin-top: 32px;
        h2 {
            text-align: center;
            color: #fff;
            margin-bottom: 17px;
        }
        .row {
            padding-top: 15px;
            padding-bottom: 15px;
            .card {
                height: 100%;
                background: #FFFFFF;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
                border-radius: 4px;
                border: 0;
                .header {
                    margin-top: 24px;
                    font-weight: bold;
                    @include h4;
                }
                .description {
                    @include h5;
                }
            }
        }
    }

    .data-presentation-block{
        margin-top: 80px;
        h2{
            text-align: center;
        }
        .row {
            padding-top: 15px;
            padding-bottom: 15px;
            .header {
                font-weight: bold;
                margin-bottom: 4px;
            }
        }
    }

    .contact-form {
        margin-top: 80px;
    }
}