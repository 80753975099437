@import "../variables.scss";
@import "../mixins.scss";

.landing-page {
    .first {
        color: #fff;

        h1 {
            font-family: "Degular Display - Bold", sans-serif;
            font-weight: bold;
            font-size: 3.4rem;
            font-weight: 700;
            line-height: 1;
        }

        .lead {
            font-size: 1.25rem;
            line-height: 1.5;
        }

        .badge {
            font-size: 18px;
            opacity: 0.7;
            font-weight: normal;
        }
    }

    .designer-row {
        background-color: #e1e7ed;

        h2 {
            @include h1;
        }
    }

    .row {

        h2#learn-more {
            @include h1;
            margin-top: 80px;
        }

        .card {
            height: 100%;
            background: #e1e7ed;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
            border-radius: 4px;
            border: 0;

            .header {
                margin-top: 24px;
                font-weight: bold;
            }
        }
    }

    .page-top-bg {
        @keyframes slideBackground {
            0% {
                background-position: 0% 50%;
            }

            100% {
                background-position: 100% 50%;
            }
        }

        .dotted-bg {
            background-image: url("../images/top.svg");
            background-size: cover;
            background-position: 0% 50%;
            animation: slideBackground 20s infinite alternate;
            height: inherit;
            opacity: 0.3;
        }
    }
}

@media (max-width: 500px) {
    .landing-page {
        .page-top-bg {
            height: 830px;
        }
    }
}


@media (min-width: 500px) and (max-width: 769px) {
    .landing-page {
        .page-top-bg {
            height: 820px;
        }
    }
}

@media (min-width: 770px) and (max-width: 999px) {
    .landing-page {
        .page-top-bg {
            height: 580px;
        }
    }
}

@media (min-width: 1000px) {
    .landing-page {
        .page-top-bg {
            height: 510px;
        }
    }
}