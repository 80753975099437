@import '../variables.scss';
@import '../mixins.scss';

.use-cases-page {
    .use-cases-block {
        margin-top: 32px;
        h2 {
            text-align: center;
            color: #fff;
            margin-bottom: 17px;
        }
        .row {
            padding-top: 15px;
            padding-bottom: 15px;
            .card {
                height: 100%;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
                border-radius: 4px;
                border: 0;
                .header {
                    margin-top: 14px;
                    font-weight: bold;
                    @include h4;
                }
                .description {
                    @include h5;
                }
                /*
                &:hover {
                    background: $surface-blue;
                    color: $purple-light;
                }*/
            }
        }
    }
    
    .match-content-and-per-type-block {
        margin-top: 20px;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
        border-radius: 4px;

        .match-content-block {
            h4{
                text-align: center;
            }
            h5{
                font-weight: bold;
                margin-top: 12px;
                text-align: center;
                margin-bottom: 22px;
            }
            .row {
                padding-top: 15px;
                padding-bottom: 15px;
                .card {
                    height: 100%;
                    .header{
                        @include h6;
                        font-weight: bold;
                    }
                    img{
                        float: left;
                        margin-right: 8px;
                    }
                    .description{
                        @include h7;
                        color: $dark-gray;
                        padding-top: 12px;
                    }
                }
            }
        }

        .per-type-block{
            margin-top: 60px;
            h5{
                text-align: center;
                margin-bottom: 32px;
            }
        }
    }

    .industry-block{
        margin-top: 56px;

        .btn {
            margin-top: 38px;
        }

        h2{
            text-align: center;
            margin-bottom: 32px;
        }
        .row{
            .card{
                height: 158px;
                text-align: center;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
                border-radius: 4px;
                border: 0;
                .header{
                    margin-top: 12px;
                }
            }
        }
    }

    .contact-form {
        margin-top: 80px;
    }
}